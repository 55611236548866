.chat-box {
  background: #ececec;
  padding: 0;
  padding-top: 15px;
  height: calc(100vh - 190px);
}

.message-list {
  height: calc(100% - 65px);
}

.input-nome-sindico {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
}

.chat-box-footer {
  display: inline-block;
  width: calc(100% - 30px);
  margin: 15px;
  background: #fff;
}

.chat-box-footer .box-interagir {
  text-align: center;
  padding: 10px 0;
}

.rce-mbox-title > span {
  width: 100%;
  line-height: initial;
}

.rce-mbox-title--clear {
  margin-bottom: 0;
}

.message-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.message-dropdown > span {
  float: left;
}

.message-dropdown > button {
  float: right;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 10px;
}

.message-dropdown > button > i {
  margin: 0;
  color: #868686;
}

.message-dropdown-content {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100px;
  right: 25px;
  top: 2px;
  z-index: 2;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26), 0 2px 10px 0 rgba(0, 0, 0, .16);
}

.message-dropdown-content > li > button {
  display: inline-block;
  background: #fff !important;
  width: 100%;
}

.message-dropdown-content > li > button:hover {
  background: #efefef !important;
}
