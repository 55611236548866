.notificacao-mobile .header span,
.notificacao-mobile .header a {
  display: inline-block;
  width: 100%;
  padding: 3px 0;
}

.notificacao-mobile .align-buttons {
  display: inline-block;
  width: 100%;
  margin-top: 15px;
  text-align: right;
}

.notificacao-mobile .align-buttons i {
  margin-right: 0 !important;
}

.notification-header {
  word-wrap: break-word;
  font-size: 1.2em;
  margin-bottom: 5px;
  line-height: 1.2em;
}