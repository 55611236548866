.plano {
  cursor: pointer;
  position: relative;
  outline: none;
}

.plano:hover {
  border-color: #9e9e9e;
}

.plano.selected {
  border-color: #622dcb;
}

.plano.no-click {
  cursor: default;
}

.plano span {
  font-size: 22px;
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-top: 15px;
}

.plano span b {
  font-size: 46px;
  color: #622dcb;
}

.plano ul {
  list-style-type: none;
  margin: 20px 0;
  padding-left: 25px;
}

.plano ul li {
  margin: 5px 0;
  text-align: left;
}

.plano .selected {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  font-size: 22px;
  top: 20px;
  right: 20px;
  color: #fff;
  background: #2ecc40;
}

.plano .selected i {
  margin-left: 5px;
}
